import { useState} from "react";
import Pane from "../ui/Pane";
import { apiConfig } from "../../apiConfig";

function VideoInputTest(props) {
    const [testPassed, setTestPassed] = useState(false);
    const handlePassCheck = (event) => {
        setTestPassed(event.target.checked);
    }

    const [testNotes, setTestNotes] = useState("");
    const handleNotesUpdate = (event) => {
        setTestNotes(event.target.value);
    }


    const startVideoTest = () => {
        //dispatch({type: "tested"});
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/startVideoTest", {
            method: "POST",
            //body: JSON.stringify(body),
            
            //headers: new Headers({ Authorization: props.apiKey, 'content-type': 'application/json' })
        })
        .then((response) => {
            props.setVideoInTest(true);
            setTimeout(() => {checkVideoFound()}, 4000);
        })
        //.then((data => {
        //    console.log(data);
        //}))
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
        })
    }

    const checkVideoFound = () => {
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/videoFound", {
            method: "POST",
            //body: JSON.stringify(body),
            
            //headers: new Headers({ Authorization: props.apiKey, 'content-type': 'application/json' })
        })
        .then((response) => {
            return response.json();
            //dispatch({type: "finish"});
            
        })
        .then((data) => {
            setTestPassed(data.videoFound);
            setTestState("finish");
        })
        //.then((data => {
        //    console.log(data);
        //}))
        .catch((err) => {
            console.log(err);
        })
    }


    const sendTestReport = () => {
        //dispatch({type: "complete"});
        setTestState("complete");
        const report = {
            passVideo: testPassed,
            videoNotes: testNotes
        }
        console.log(report);
       props.roomReportUpdate(report);
       props.setVideoInTest(false);
       props.setVideoTestComplete(true);
       props.setVideoTestComplete(true);
    }

    const initialState = (<div>
            <button onClick={startVideoTest} disabled={props.isReadOnly}>Start Video Test</button>
        </div>);

    const [testState, setTestState] = useState("initial");

    let content;

    //const reducer = (state, action) =>{
        switch (testState) {
            case "finish":
                content = <div>
                <textarea 
                    onChange={handleNotesUpdate}
                    value={testNotes}>
                    </textarea>
                <label>
                        <input 
                            type="checkbox" 
                            checked={testPassed}
                            onChange={handlePassCheck}
                        />
                        Test Passed
                    </label>
                <button onClick={sendTestReport}>Complete Test</button>
                </div>;
                break;
            case "complete":
                content = <div>
                    <label>Test Complete</label>
                    <button onClick={startVideoTest} disabled={props.isReadOnly}>Test Again</button>
                    </div>;
                    break;
            default:
                content = initialState;
                break;
        }
    //}
    //const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Pane>
            <h2>Video Input</h2>
            <label>
                This will test the video input into the system.<br/>
                There is nothing recorded, just check the screen to see the video feed.<br/>
                There will be a 4 second delay to see if video is being detected by the test.
            </label>
            {content}
        </Pane>
    )
}

export default VideoInputTest;