import { useState} from "react";
import Pane from "../ui/Pane";
import { apiConfig } from "../../apiConfig";

function AudioTest(props){
    const [testPassed, setTestPassed] = useState(false);
    const handlePassCheck = (event) => {
        setTestPassed(event.target.checked);
    }

    const [testNotes, setTestNotes] = useState("");
    const handleNotesUpdate = (event) => {
        setTestNotes(event.target.value);
    }

    const [runningTest, setRunningTest] = useState(false);

    let audioPlayTimeout;
   

    const startAudioTest = () => {
        
        //send API command
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/startAudioTest", {
            method: "POST",
            
        })
        .then((response) => {
            props.setAudioInTest(true);
            setTestState("record");
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
        })
    };
    const playAudio = () => {
        //send API
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/playFile", {
            method: "POST",
        })
        .then((response) => {
            setRunningTest(true);
            audioPlayTimeout = setTimeout(() => {setRunningTest(false)}, 60000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
        
    };
    const startRecord = () => {
        
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/startRecording", {
            method: "POST",
        })
        .then((response) => {
            //setTestState("recording");
            setRunningTest(true);
            audioPlayTimeout = setTimeout(() => {stopRecord()}, 30000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
        //send API
    };
    const stopRecord = () => {
        //send API
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/stopRecording", {
            method: "POST",
        })
        .then((response) => {
            //setTestState("play");
            //console.log(response);
            return response.json();
        })
        .then((data => {
            //setTestState("play");
            audioPlayTimeout = setTimeout(() => {playRecord()}, 30000);
            setTestPassed(data.audioFound);
        }))
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
    };
    const playRecord = () => {
        //send API
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/playRecording", {
            method: "POST",
        })
        .then((response) => {
            audioPlayTimeout = setTimeout(() => {setRunningTest(false)}, 30000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
    };
    const finishTest = () => {
        setTestState("finish");
        
    };
    const sendTestReport = () => {
        setTestState("complete");
        const report = {
             passAudio: testPassed,
             audioNotes: testNotes
         }
         console.log(report);
        props.roomReportUpdate(report);
        props.setAudioInTest(false);
        props.setAudioTestComplete(true);
    };

    const stopAudioPlaying = () => {
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/stopPlayer", {
            method: "POST",
        })
        .then((response) => {
            //setTimeout(() => {setRunningTest(false)}, 30000);
            setRunningTest(false);
            clearTimeout(audioPlayTimeout);
        })
        .catch((err) => {
            console.log(err);
            //alert("Error starting test.");
            //setRunningTest(false);
        })
    }
    
    let testingContent;
    if(!runningTest){
        testingContent = <div>
            <label>Press a button.</label>
        </div>;
    } else{
        testingContent = <div>
            <label style={{color: "red"}}>Testing!</label>
            <button onClick={stopAudioPlaying}>Stop Audio</button>
        </div>;
    }

    const [testState, setTestState] = useState("initial");

    let content;

    switch (testState) {
        case "record":
            content = <div>
                <div>
                    <label>
                        Pressing "Record and Play" will record audio from the meeting for 30 seconds, then play the audio recording.<br/>
                        Pressing "Play Audio" will play a pre loaded audio file over the meeting.<br/>
                        Once finished you can add notes to finalize the test.
                    </label>
                    {testingContent}
                </div>
            <button onClick={startRecord} disabled={runningTest}>Record and Play</button>
            <button onClick={playAudio} disabled={runningTest}>Play Audio</button>
            <button onClick={finishTest} disabled={runningTest}>Finish Test</button>
            </div>;
            break;
        /*case "recording":
            content = <div>
            <button onClick={stopRecord}>Stop Recording</button>
            </div>;
            break;
        case "play":
            content = <div>
            <button onClick={startRecord}>Re-record</button>
            <button onClick={playRecord}>Play Recording</button>
            <button onClick={playAudio}>Play Audio</button>
            <button onClick={finishTest}>Finish Test</button>
            </div>;
            break;*/
        case "finish":
            content = <div>
            <textarea 
                onChange={handleNotesUpdate}
                value={testNotes}>
                </textarea>
            <label>
                    <input 
                        type="checkbox" 
                        checked={testPassed}
                        onChange={handlePassCheck}
                    />
                    Test Passed
                </label>
            <button onClick={sendTestReport}>Complete Test</button>
            </div>;
            break;
        case "complete":
            content = <div>
                        <label>Test Complete</label>
                        <button onClick={startAudioTest} disabled={props.isReadOnly}>Test Again</button>
                        </div>;
            break;
        default:
            content = <div>
                    <div>
                        <label>
                            Click to start the audio test.
                        </label>
                    </div>
                    <div>
                        <button onClick={startAudioTest} disabled={props.isReadOnly}>Start Test</button>
                    </div>
                </div>;
            break;
    }

    return (
        <Pane>
            <h2>Audio Test</h2>
            <label>
                This will test the audio for the system.
            </label>
            {content}
        </Pane>
        
    )
}

export default AudioTest;