import { Route, Switch } from "react-router-dom";

import Layout from "./components/layout/Layout";
import CallInStationPage from "./pages/CallInStationPage"

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <AuthenticatedTemplate>
            <CallInStationPage />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>Please login...</p>
          </UnauthenticatedTemplate>
        </Route>
        <Route path="/callInStation">
          <AuthenticatedTemplate>
            <CallInStationPage />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>Please login...</p>
          </UnauthenticatedTemplate>
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
