//import { Link } from "react-router-dom";

import classes from "./MainNavigation.module.css";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../ui/SignInButton";
import { SignOutButton } from "../ui/SignOutButton";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../../authConfig";

function MainNavigation() {
  //   const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  //   if (!isAuthenticated) {
  //     instance.loginRedirect(loginRequest).catch((e) => {
  //       console.error(e);
  //     });
  //   }

  return (
    <header className={classes.header}>
      <img className={classes.img} src={"../../../../logoIcon.png"}></img>
      <div className={classes.logo}>Call-In Station</div>
      {/* <nav>
        <ul>
          <li>
            <Link to="/callInStation">Price List Import</Link>
          </li>
        </ul>
      </nav> */}
      <div>{isAuthenticated ? <SignOutButton /> : <SignInButton />}</div>
    </header>
  );
}

export default MainNavigation;
