import CertifyRoom from "../components/callin/CertifyRoom";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { InteractionStatus } from "@azure/msal-browser";

function CallInStationPage(){
    const {instance, accounts, inProgress} = useMsal();
    const [apiKey, setApiKey] = useState(null);
  useEffect(() => {
    async function fetchKey() {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        await instance
          .acquireTokenSilent(request)
          .then((response) => {
            setApiKey(response.idToken);
          })
          .catch(async (e) => {
            console.log(e);
            await instance.acquireTokenPopup(request).then((response) => {
              setApiKey(response.idToken);
            });
          });
      }
    }
    fetchKey();
  }, [instance, accounts, inProgress]);

    return (
        <section>
            <CertifyRoom apiKey={apiKey}/>
        </section>
    );
}

export default CallInStationPage;