import CreateMeeting from "../callin/CreateMeeting";
import { useState } from "react";
import AudioTest from "./AudioTest";
import VideoInputTest from "./VideoInputTest";
import ContentSharingTest from "./ContentSharingTest";
import FinishTest from "./FinishTest";
import { apiConfig } from "../../apiConfig";

function CertifyRoom(props) {
    const [isMeetingJoined, SetMeetingJoined] = useState(false);
    const [callLegId, SetCallLegId] = useState("");
    const [startTime, SetStartTime] = useState(null);
    const [isCertified, setIsCertified] = useState(false);

    const [audioInTest, setAudioInTest] = useState(false);
    const [audioTestComplete, setAudioTestComplete] = useState(false);
    const [videoInTest, setVideoInTest] = useState(false);
    const [videoTestComplete, setVideoTestComplete] = useState(false);
    const [contentInTest, setContentInTest] = useState(false);
    const [contentTestComplete, setContentTestComplete] = useState(false);


    const [roomReport, setRoomReport] = useState({});

    const updateRoomReport = (items) => {
        setRoomReport(roomReport => ({
            ...roomReport, ...items
        }));
    }

    const overallNotesHandler = (event) =>{
        const notes = {
            overallNotes: event.target.value
        }
        updateRoomReport(notes);
    }

    const projectNumberHandler = (event) => {
        const notes = {
            projectNumber: event.target.value
        }
        updateRoomReport(notes);
    }

    const roomNameHandler = (event) => {
        const notes = {
            roomName: event.target.value
        }
        updateRoomReport(notes);
    }

    const meetingJoined = (joined, callLegId) =>{
        SetMeetingJoined(joined);
        SetCallLegId(callLegId);
        SetStartTime(Date.now());
        setAudioInTest(false);
        setVideoInTest(false);
        setContentInTest(false);
        setAudioTestComplete(false);
        setVideoInTest(false);
        setContentInTest(false);
    };


    const sendRoomReport = () => {
        //setIsLoading(true);
        if (isCertified){
            alert("Room report has already been sent.")
        } else {
            const totalSeconds = Math.floor((Date.now() - startTime)/1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            roomReport.CallDuration = `${minutes}:${seconds.toString().padStart(2, '00')}`;
           fetch(apiConfig.graph_service + "v1/sendRoomReport", {
               method: "POST",
               headers: new Headers({ Authorization: props.apiKey }),
               body: JSON.stringify(roomReport)
           })
               .then((response) => {
                   return response.json();
               })
               .then((data => {
                   console.log(data);
                   setIsCertified(true);
               }))
               .catch((err) => {
                   console.log(err);
                   //setIsLoading(false);
               })
        }
         
    }

    let audioReadOnly = videoInTest || contentInTest;
    let videoReadOnly = audioInTest || contentInTest;
    let contentReadonly = audioInTest || videoInTest;
    let reportSendable = audioTestComplete && videoTestComplete && contentTestComplete;

    let content;
    if(isMeetingJoined) {
        content = (
        <div>
            <AudioTest key={startTime}
                callLegId={callLegId} 
                roomReportUpdate={updateRoomReport} 
                setAudioInTest={setAudioInTest}
                setAudioTestComplete={setAudioTestComplete}
                isReadOnly={audioReadOnly}
            />
            <VideoInputTest key={startTime}
                callLegId={callLegId} 
                roomReportUpdate={updateRoomReport} 
                setVideoInTest={setVideoInTest}
                setVideoTestComplete={setVideoTestComplete}
                isReadOnly={videoReadOnly}
            />
            <ContentSharingTest key={startTime}
                callLegId={callLegId} 
                roomReportUpdate={updateRoomReport} 
                setContentInTest={setContentInTest}
                setContentTestComplete={setContentTestComplete}
                isReadOnly={contentReadonly}
            />
            <FinishTest key={startTime}
                overallNotesHandler={overallNotesHandler} 
                sendRoomReport={sendRoomReport}
                isReadOnly={!reportSendable}
                projectNumberHandler={projectNumberHandler}
                roomNameHandler={roomNameHandler}
            />
        </div>
        );
    } else {
        content = <div></div>;
    }

    return (
        <div>
            <div>
                <CreateMeeting apiKey={props.apiKey} meetingJoined={meetingJoined}/>
            </div>
            <div>
                {content}
            </div>
            
        </div>
    )
}

export default CertifyRoom;