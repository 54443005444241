import { useState} from "react";
import Pane from "../ui/Pane";
import { apiConfig } from "../../apiConfig";

function ContentSharingTest(props) {
    const [testPassed, setTestPassed] = useState(false);
    const handlePassCheck = (event) => {
        setTestPassed(event.target.checked);
    }

    const [testNotes, setTestNotes] = useState("");
    const handleNotesUpdate = (event) => {
        setTestNotes(event.target.value);
    }

    const [runningTest, setRunningTest] = useState(false);

    const startContentTest = () => {
        setTestState("record");
        props.setContentInTest(true);
    }

    const startScreenRecord = () => {
        
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/startRecordScreenShare", {
            method: "POST",
        })
        .then((response) => {
            //setTestState("recording");
            setRunningTest(true);
            setTimeout(() => {stopScreenRecord()}, 30000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
    }

    const stopScreenRecord = () => {
        
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/stopRecordScreenShare", {
            method: "POST",
        })
        .then((response) => {
            //setTestState("play");
            setRunningTest(true);
            setTimeout(() => {playScreenRecord()}, 30000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
    }

    const playScreenRecord = () => {
        //dispatch({type: "tested"});
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/playRecordScreenShare", {
            method: "POST",
        })
        .then((response) => {
            //dispatch({type: "recording"});
            return response.json();
        })
        .then((data) => {
            if(!data.audioFound){
                alert("Content audio not detected.");
            } else if (!data.videoFound){
                alert("Content video not detected.");
            } else {
                setTestPassed(true);
            }
            setTimeout(() => {
                setRunningTest(false); 
                shareBotScreen();
            }, 30000);
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
            setRunningTest(false);
        })
    }

    const shareBotScreen = () => {
        //dispatch({type: "tested"});
        fetch(apiConfig.bot_service + "/calls/" + props.callLegId + "/startScreenShare", {
            method: "POST",
        })
        .then((response) => {
            //dispatch({type: "record"});
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
        })
    }

    const finishTest = () => {
        setTestState("finish");
    };

    const sendTestReport = () => {
        setTestState("complete");
        const report = {
             passContent: testPassed,
             contentNotes: testNotes
         }
         console.log(report);
        props.roomReportUpdate(report);
        props.setContentInTest(false);
        props.setContentTestComplete(true);
    };

    const [testState, setTestState] = useState("initial");


    const initialState = (
        <div>
            <button onClick={startContentTest} disabled={props.isReadOnly}>Test Content Sharing!</button>
            {/* <button onClick={splashScreen}>Splash</button> */}
        </div>
    );

    let testingContent;
    if(!runningTest){
        testingContent = <div>
            <label>Press a button.</label>
        </div>;
    } else{
        testingContent = <div>
            <label style={{color: "red"}}>Testing!</label>
        </div>;
    }

    let content;

    //const reducer = (state, action) =>{
        switch (testState) {
            case "record":
                content = (<div>
                    <div>
                        <label>
                            To test the screen sharing, share your screen now.<br/>
                            Ensure you have clicked share audio on your screen share.<br/>
                            Pressing "Record and Play Screen" will record audio and content shared from the meeting for 30 seconds, <br/>
                            then play the audio recording.<br/>
                            Once finished you can add notes to finalize the test.
                        </label>
                        {testingContent}
                    </div>
                    <button onClick={startScreenRecord} disabled={runningTest}>Record and Play Screen</button>
                    <button onClick={finishTest} disabled={runningTest}>Finish Test</button>
                </div>);
                break;
            /*case "recording":
                content = (<div>
                    <button onClick={stopScreenRecord}>Stop Screen Record</button>
                </div>);
                break;
            case "play":
                content = (<div>
                    <button onClick={startScreenRecord}>Re-record Screen</button>
                    <button onClick={playScreenRecord}>Play Screen Recording</button>
                    <button onClick={shareBotScreen}>Share Bot Screen</button>
                    <button onClick={finishTest}>Finish Test</button>
                </div>);
                break;*/
            case "finish":
                content = (<div>
                    <textarea 
                        onChange={handleNotesUpdate}
                        value={testNotes}>
                        </textarea>
                    <label>
                            <input 
                                type="checkbox" 
                                checked={testPassed}
                                onChange={handlePassCheck}
                            />
                            Test Passed
                        </label>
                    <button onClick={sendTestReport}>Complete Test</button>
                    </div>);
                    break;
            case "complete":
                content = (<div>
                    <label>Test Complete</label>
                    <button onClick={startContentTest} disabled={props.isReadOnly}>Test Again</button>
                    </div>);
                    break;
            default:
                content = initialState;
                break;
        }
    //}
    //const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Pane>
            <h2>Content Sharing</h2>
            {content}
        </Pane>
    )
}

export default ContentSharingTest;