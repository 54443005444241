import Pane from "../ui/Pane";

function FinishTest(props){

    return <Pane>
        <div>
            <label>Project Number:</label>
            <input type="text" onChange={props.projectNumberHandler}/>
        </div>
        <div>
            <label>Room Name/Number:</label>
            <input type="text" onChange={props.roomNameHandler}/>
        </div>
        <div>
            <div>
                <label>Final testing notes:</label>
            </div>
            <div>
                <textarea 
                    onChange={props.overallNotesHandler}>
                </textarea>
            </div>
            <button onClick={props.sendRoomReport}  disabled={props.isReadOnly}>Submit Report</button>
        </div>
    </Pane>;
}

export default FinishTest;