import { useState} from "react";
import Modal from "../ui/Modal";
import { apiConfig } from "../../apiConfig";
import Pane from "../ui/Pane";
import classes from "./CreateMeeting.module.css";

function CreateMeeting(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [meetingInfo, setMeetingInfo] = useState("");
    const [showMeetingInfo, setShowMeetingInfo] = useState(false);
    const [showJoinedInfo, setShotJoinedInfo] = useState(false);
    const [callMeetingId, setCallMeetingId] = useState("");
    const [meetingLink, setMeetingLink] = useState("");
    
    


    const createMeetingPress = () => {
        setIsLoading(true);
        fetch(apiConfig.graph_service + "v1/createMeeting", {
            method: "POST",
            headers: new Headers({ Authorization: props.apiKey })
        })
            .then((response) => {
                return response.json();
            })
            .then((data => {
                console.log(data);
                //setIsLoading(false);
                setMeetingInfo(data);
                setShowMeetingInfo(true);
                setMeetingLink(data.joinWebURL);
                console.log("before timeout");
                setTimeout(() => {joinMeetingWithLink(data.joinWebURL)}, 0);
            }))
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
    };

    const splashScreen = (callLegId) => {
        fetch(apiConfig.bot_service + "/calls/" + callLegId + "/splashScreen", {
            method: "POST",
        })
        .then((response) => {
            console.log("splash");
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
        })
    }

    const handleScreenShare = () => {
        shareScreen(callMeetingId);
    }

    const shareScreen = (callLegId) => {
        fetch(apiConfig.bot_service + "/calls/" + callLegId + "/startScreenShare", {
            method: "POST",
        })
        .then((response) => {
            //dispatch({type: "record"});
        })
        .catch((err) => {
            console.log(err);
            alert("Error starting test.");
        })
    }

    const joinMeeting = () => {
        joinMeetingWithLink(meetingLink);
    }

    const joinMeetingWithLink = (newLink) => {
        setIsLoading(true);
        const body = {
            joinURL: newLink
        };
        
        fetch(apiConfig.bot_service + "/joinCall", {
            method: "POST",
            body: JSON.stringify(body),
            
            headers: new Headers({ Authorization: props.apiKey, 'content-type': 'application/json' })
        })
        .then((response) => {
            return response.json();
        })
        .then((data => {
            console.log(data);
            console.log("join meeting");
            setIsLoading(false);
            
            props.meetingJoined(true, data.callId);
            setCallMeetingId(data.callId);
            if(!showMeetingInfo){
                setShotJoinedInfo(true);
            }
            
            setTimeout(() => {shareScreen(data.callId);}, 2000);
            setTimeout(() => {splashScreen(data.callId)}, 8000);
            
        }))
        .catch((err) =>{
            console.log(err);
            setIsLoading(false);
        })
        
    };



    const leaveMeeting = () => {
        fetch(apiConfig.bot_service + "/calls/" + callMeetingId, {
            method: "DELETE",
            
            headers: new Headers({ Authorization: props.apiKey, 'content-type': 'application/json' })
        })
        .then((response) => {
            setShotJoinedInfo(false);
            setShowMeetingInfo(false);
            props.meetingJoined(false, "");
        })
        .catch((err) =>{
            console.log(err);
            setIsLoading(false);
        })
    };

    let meetingHTML;
    if (showMeetingInfo) {
        meetingHTML = (
        <div>
            <div>
                <label>Meeting join: </label>
                <div>
                <textarea readOnly cols={135} rows={2} className={classes.textarea} value={meetingLink}></textarea>
                </div>
            </div>
            <div>
                <label>SIP Address: </label>
                <textarea readOnly cols={35} rows={1} className={classes.textarea} value={meetingInfo.sipId}></textarea>
            </div>
            <div>
                <label>Start Time: {meetingInfo.startDateTime}</label>
            </div>
            <div>
                <label>End Time: {meetingInfo.endDateTime}</label>
            </div>
            <div>
                    <button onClick={leaveMeeting}>Leave Meeting</button> {/* Need to leave first then join in prod */}
                    {/*<button onClick={handleScreenShare}>Screen Share</button>   */} 
            </div>
        </div>
        );
    } else if(showJoinedInfo){
        meetingHTML = (
            <div>
                <label>Meeting join: </label>
                <div>
                <textarea readOnly cols={135} rows={2} className={classes.textarea} value={meetingLink}></textarea>
                </div>
                <div>
                    <button onClick={leaveMeeting}>Leave Meeting</button> {/* Need to leave first then join in prod */}
                </div>
            </div>
        );
    
    } else {
        meetingHTML = (
        <div>
            <div>
                <label>
                    Please click create meeting and a new meeting will be created on behalf of your RoomReady email address.
                    
                </label>
            </div>
            <div>
                <button onClick={createMeetingPress}>Create Meeting</button>
            </div>
            <div>
                <label>--OR--</label>
            </div>
            <div>
                <label>
                If you wish to join an existing meeting please provide the teams join link in the input below.
                </label>
            </div>
            <div>
                <label>Teams Join Meeting Link: </label>
                <input type="text" value={meetingLink} onChange={e => setMeetingLink(e.target.value)}/>
                <button onClick={joinMeeting}>Join</button>
            </div>
        </div>
        );
    }

    return (<div>
        {isLoading && <Modal />}
        <Pane>
            {meetingHTML}
        </Pane>

    </div>);
}

export default CreateMeeting;